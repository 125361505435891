import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './Contact.scss'
import { Helmet } from "react-helmet";
import blog4 from '../../Images/blog4.jpg'
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import contactusbg from '../../Images/contactusbg.png'
import hvacImg2 from '../../Images/hvacImg2.jpg'

export default function Contact() {
    const navigate = useNavigate()
    const [faq, setFaq] = useState([
        {
            question: "Do FONTANA Heating & Air Conditioning  conduct a hard or soft credit inquiry?",
            answer: `
            <p>
            When assessing eligibility, FONTANA Heating & Air Conditioning  conducts a soft credit check on the personal credit of the business owner.
            </p >
            `
        },
        {
            question: "rewrite What information do I need to apply?",
            answer: `
            <p>
            Usually, we only need fundamental information about you and your business. We will request:
            </br>
            </br>
            1. Business Tax ID.
            </br>
            </br>
            2. The last three months of business bank statements.
            </br>
            </br>
            3. Social Security number of the business owner(s).
            </p>
            `
        },
        {
            question: "What loan amounts and durations does FONTANA Heating & Air Conditioning  offer?",
            answer: `
            <p>
            Our term loans span from $5,000 to $250,000, with terms ranging from 3 to 24 months.
            </br>
            </br>
            Our lines of credit can reach up to $100,000, and they operate on a revolving basis, meaning as you reduce your balance, your available funds grow.
            </p>
            `
        },
        {
            question: "Which sectors does FONTANA Heating & Air Conditioning  cater to?",
            answer: `
            <p>
            FONTANA Heating & Air Conditioning  collaborates with over 700 diverse business types, including restaurants, auto repair shops, healthcare professionals, contractors, and various other service providers.
            </br>
            </br>
            However, we do not provide services to these excluded industries.
            </p>
            `
        },
    ]);
    const [faqShow, setFaqShow] = useState({ index: 0, valid: true });

    function sendEmail(e) {
        // setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_vdkxpnd', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                // setPage1(false)
                // setPage2(false)
                // setPage3(true)
                // setFormSubmiting(false)
                // navigate('/thankpage')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
                navigate('/thanks')
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="Contact">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Heating & Air Conditioning | Contact Us In Fontana, CA</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Heating & Air Conditioning | Contact Us In Fontana, CA" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Heating & Air Conditioning | Contact Us In Fontana, CA" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.fontanahvacairconditioning.org/contact" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Contact Heating & Air Conditioning for expert He In Fontana, CAating & Air Conditioning, inspection, repair, and maintenance services in Fontana, CA. Reach us by phone or visit our website to schedule your service today.Contact Heating & Air Conditioning for expert He In Fontana, CAating & Air Conditioning, inspection, repair, and maintenance services in Fontana, CA. Reach us by phone or visit our website to schedule your service today." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Contact Heating & Air Conditioning for expert He In Fontana, CAating & Air Conditioning, inspection, repair, and maintenance services in Fontana, CA. Reach us by phone or visit our website to schedule your service today.Contact Heating & Air Conditioning for expert He In Fontana, CAating & Air Conditioning, inspection, repair, and maintenance services in Fontana, CA. Reach us by phone or visit our website to schedule your service today." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Heating & Air Conditioning | Contact Us In Fontana, CA" data-react-helmet="true" />
                <meta name="keywords" content="HVAC services Fontana CA, Air conditioning repair Fontana, Heating repair Fontana CA, HVAC installation Fontana, Furnace repair Fontana, AC maintenance Fontana CA, Air duct cleaning Fontana, Emergency HVAC services Fontana, HVAC contractors Fontana CA, Residential HVAC Fontana, Commercial HVAC Fontana, HVAC tune-up Fontana, AC replacement Fontana, Heating system installation Fontana, Indoor air quality Fontana CA, HVAC repair near me Fontana, Central air conditioning Fontana, Ductless mini-split Fontana, HVAC service plan Fontana, Thermostat installation Fontana, 24/7 HVAC service Fontana, Energy-efficient HVAC Fontana, Air filter replacement Fontana, Heat pump repair Fontana, HVAC maintenance Fontana CA, HVAC inspection Fontana, Air conditioner installation Fontana, Heating and cooling Fontana, AC service Fontana CA, HVAC system replacement Fontana, Furnace installation Fontana CA, HVAC repair cost Fontana, Local HVAC company Fontana, Affordable HVAC Fontana, AC troubleshooting Fontana, HVAC technicians Fontana CA, HVAC emergency repair Fontana, Heater repair Fontana CA, Air conditioning services Fontana, Commercial air conditioning Fontana, Furnace tune-up Fontana, Ductwork repair Fontana CA, HVAC financing Fontana, HVAC deals Fontana CA, Heating and air conditioning Fontana, HVAC consultation Fontana, Central heating repair Fontana, HVAC contractor near me Fontana, Reliable HVAC Fontana CA, Licensed HVAC contractor Fontana" data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Get help from one of our team .</h1>
                    <h2>Monday - Sunday 8 a.m. - 8:00 p.m. ET</h2>
                </div>
                <div className="MainBannerRight">
                    <img src={contactusbg} alt="FONTANA Heating & Air Conditioning  contact us" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>Here is how we can help you today.</h2>
                    <div className="MainBanner2TopBoxMenu">
                        <div className="MainBanner2TopBoxBox">
                            <h3>Help with a new loan.</h3>
                            <a target='_blank' href="mailto:info@fontanahvacairconditioning.org">info@fontanahvacairconditioning.org</a>
                            <a target='_blank' href="tel:951-724-3371">951-724-3371</a>
                        </div>
                        <div className="MainBanner2TopBoxBox">
                            <h3>Mailing address.</h3>
                            <a>Fontana California</a>
                        </div>
                        <div className="MainBanner2TopBoxBox">
                            <h3>Partner with us.</h3>
                            <a target='_blank' href="mailto:admin@fontanahvacairconditioning.org">admin@fontanahvacairconditioning.org</a>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="ContactBanner2">
                <h2>Do you have another question?</h2>
                <a href='/faq'>Please take a look at our Frequently Asked Questions <u> (FAQ) page.</u> </a>
            </div>
            <div className="ContactFirst">
                <div className="ContactFirstHeader">
                    <h1>Send us an email</h1>
                </div>
                <div className="ContactFirstBody">
                    <div className='ContactTop'>
                        <img src={hvacImg2} alt="" />
                        <div className="ContactTopText">
                            <h1>Contact us</h1>
                            <h3>For professional Heating & Air Conditioning repair services, contact Fontana Sweep. Our certified technicians are dedicated to providing reliable and high-quality repairs to keep your Heating & Air Conditioningsafe and functional.</h3>
                        </div>
                    </div>
                    <form className="contact-form" onSubmit={sendEmail}>
                        <h4>Please feel free to send us an email!</h4>
                        <h5>Don’t wait until your Heating & Air Conditioning becomes a major problem. Regular maintenance and timely repairs can save you from costly issues down the road. Whether you need a simple tune-up or a complete installation, our team is here to help.</h5>
                        <h2>Full Name</h2>
                        <input type="text" name="Full_Name" required />
                        <h2>Email</h2>
                        <input type="email" name="Email" required />
                        <div className='ContactFirsthhh'>
                            <div className="ContactFirsthhhBox">
                                <h2>Phone</h2>
                                <input type="tel" name="Phone" required />
                            </div>
                            <div className="ContactFirsthhhBox">
                                <h2>State</h2>
                                <input type="text" name="Country" required />
                            </div>
                        </div>
                        <h2>Massage</h2>
                        <textarea className="Message" name="Message" id="" cols="30" rows="10" required></textarea>
                        <input type="submit" value="SEND" />

                    </form>
                </div>
            </div>
            <NavigatorPath />

        </div>
    )
}

